<template>
  <v-menu
    offset-y
    left
    bottom
    :close-on-content-click="false"
    v-model="menuOpen"
    offset-overflow
  >
    <template #activator="{ on, attrs }">
      <eva-button
        dense
        v-on="on"
        v-bind="attrs"
        v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.buttonTooltip')"
        class="pa-2"
      >
        <v-icon small>
          mdi-creation-outline
        </v-icon>
      </eva-button>
    </template>

    <v-card
      width="400px"
    >
      <v-card-text>
        <template v-if="showLoader">
          <v-skeleton-loader
            v-for="i in 7"
            :key="i"
            type="text"
            class="mb-0"
            :loading="false"
            :width="`${
              Math.floor(Math.random() * 50) + 50
            }%`"
          />
        </template>
        <v-card
          outlined
          flat
          class="pa-2"
          v-else
          style="max-height: 300px; height: 300px; overflow: scroll;"
        >
          <katex-md
            :expr="feedbackSuggestion"
          />
        </v-card>
      </v-card-text>
      <template v-if="rated && !userFeedbackSent">
        <v-divider />
        <v-sheet color="grey lighten-3">
          <v-card-text>
            <v-textarea
              v-model="userFeedback"
              dense
              auto-grow
              solo
              filled
              flat
              hide-details
              class="mb-2"
              :label="rated < 0 ? $t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.userFeedback.unhelpful') : $t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.userFeedback.helpful')"
            />
            <div class="d-flex">
              <v-spacer />

              <eva-button
                dense
                primary
                @click="submitUserFeedback"
              >
                <v-icon
                  small
                >
                  mdi-send-outline
                </v-icon>
              </eva-button>
            </div>
          </v-card-text>
        </v-sheet>
        <v-divider class="pb-4" />
      </template>
      <v-card-actions class="px-4 pt-0">
        <template v-if="llmResponseLog">
          <eva-button
            dense
            @click="generateFeedback"
            v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.regenerate')"
          >
            <v-icon small>
              mdi-refresh
            </v-icon>
          </eva-button>
          <eva-button
            v-if="!rated"
            dense
            @click="rateSuggestion(-1)"
            v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.unhelpful')"
          >
            <v-icon small>
              mdi-thumb-down-outline
            </v-icon>
          </eva-button>
          <eva-button
            v-if="!rated"
            dense
            @click="rateSuggestion(1)"
            v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.helpful')"
          >
            <v-icon small>
              mdi-thumb-up-outline
            </v-icon>
          </eva-button>
        </template>
        <v-spacer />
        <keyboard-tooltip
          :key-name="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.insert.key')"
          :label-after="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.insert.tooltip')"
        >
          <eva-button
            primary
            :label="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.insert.label')"
            dense
            @click="insert"
          />
        </keyboard-tooltip>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>

import { ref, watch } from "vue"
import KatexMd from "@/components/katex-md.vue"
import axios from "axios"
import jsonApii from "@/helpers/json_apii"
import KeyboardTooltip from "@/components/shared/keyboard_tooltip.vue"
import { onKeyStroke } from "@vueuse/core"
import markdown_it from "markdown-it"
import mk from "@traptitech/markdown-it-katex"

export default {
  name: "GenerateFeedbackMenu",
  components: { KeyboardTooltip, KatexMd },
  props: {
    answer: { type: Object, required: true },
  },
  emits: ["insert"],
  setup(props, { emit }) {
    const showLoader = ref(true)
    const menuOpen = ref(false)
    const feedbackSuggestion = ref("")
    const llmResponseLog = ref(null)
    const rated = ref(null)
    const userFeedback = ref("")
    const userFeedbackSent = ref(false)

    const generateFeedback = () => {
      showLoader.value = true
      axios.post(props.answer.links.feedback_suggestion).then((response) => {
        showLoader.value = false
        feedbackSuggestion.value = mdToHtml(
          jsonApii.getData(response.data).content.text
        )
        llmResponseLog.value = jsonApii.getData(response.data)
        rated.value = null
        userFeedbackSent.value = false
      })
    }

    const mdToHtml = (string) => {
      const md = markdown_it({
        html: true,
        typography: true,
        breaks: false,
      })
      md.use(mk)
      return md.render(string)
    }

    const rateSuggestion = (rate) => {
      if (!llmResponseLog.value) return
      axios
        .patch(llmResponseLog.value.links.self, { llm_response_log : { user_rating: rate } })
        .then(() => {
          rated.value = rate
        })
    }

    const submitUserFeedback = () => {
      if (!llmResponseLog.value) return
      axios
        .patch(llmResponseLog.value.links.self, { llm_response_log : { user_feedback: userFeedback.value } })
        .then(() => {
          rated.value = true
          userFeedback.value = ""
          userFeedbackSent.value = true
        })
    }

    const insert = () => {
      if (!feedbackSuggestion.value) return
      emit("insert", feedbackSuggestion.value)
      menuOpen.value = false
    }

    onKeyStroke("Enter", (e) => {
      e.preventDefault()
      if (menuOpen.value) {insert()}
    })

    watch(menuOpen, (value) => {
      if (value) {
        feedbackSuggestion.value = ""
        generateFeedback()
      }
    })
    return {
      showLoader,
      menuOpen,
      generateFeedback,
      feedbackSuggestion,
      insert,
      rateSuggestion,
      llmResponseLog,
      userFeedback,
      rated,
      submitUserFeedback,
      userFeedbackSent,
    }
  },
}
</script>
